import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Blog } from '@templates'
import { getSeoProps } from '@data/seo'
import { prismicBlogArticleFields } from '@graphql/fragments'

export const query = graphql`
  query PrismicBlogArticlesReports {
    prismic {
      allBlog_pages {
        edges {
          node {
            heading
            seodescription
            seotitle
          }
        }
      }
      allBlog_articles(sortBy: published_DESC, where: { filter_fulltext: "FilterR" }) {
        edges {
          node {
            ...prismicBlogArticleFields
          }
        }
        totalCount
      }
    }
  }
`

const BlogPage = ({ data }) => {
  BlogPage.fragments = [prismicBlogArticleFields]
  const nestedData = get(data, 'prismic.allBlog_pages.edges[0].node')
  const initialArticles = get(data, 'prismic.allBlog_articles.edges', [])
  const totalArticles = data.prismic.allBlog_articles.totalCount
  const seo = getSeoProps(nestedData)

  return (
    <Blog
      {...nestedData}
      {...{ seo }}
      initialArticles={initialArticles}
      totalArticles={totalArticles}
      filter="FilterR"
    />
  )
}

BlogPage.query = query

export default BlogPage
